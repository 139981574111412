<template>
    <div class="login" id="login">
        <div class="login-main">
            <div class="left"></div>
            <div class="right">
                <div class="login-content">
                    <div class="login-title"><span>登&nbsp;&nbsp;陆</span></div>
                    <div class="login-line">
                        <i class="iconfont iconaccount"></i>   
                        <input @input="bindAccountInput" v-model="account" type="text" placeholder="请输入登陆账号" />
                    </div>
                    <p class="err_msg">{{account_message}}</p>
                    <div class="login-line">
                        <i class="iconfont iconpassword"></i>   
                        <input v-model="password" @input="bindPassInput" type="password" placeholder="请输入登陆密码" />
                    </div>
                    <p class="err_msg">{{pwd_message}}</p>
                    <div class="login-line login-tools"><span @click="submitLogin">立即登陆</span></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ref, onMounted } from 'vue'
import { useRoute, useRouter } from "vue-router";
import { getAuthInitInfo } from '@/api'
import { utils } from '@/utils/utils'
export default {
    name: 'login',
    setup() {
        let accountInfo = ref({})
        // 获取路由参数
        var route = useRoute();
        var router = useRouter();
        let appkey = ref()
        onMounted(function() {
            init()
            var query = route.query
            if (query && query.appkey) {
                appkey = query.appkey
            }
            console.log(appkey)
            console.log(process.env.BASR_URL)
        })

        async function init () {
            accountInfo = await getAuthInitInfo()
        }

        let account = ref()
        let account_message = ref()
        let password = ref()
        let pwd_message = ref()
        let submitLogin = () => {
            let p = true
            if (!account.value) {
                account_message.value = '请填写登陆账号!'
                p = false
            }
            if (!password.value) {
                pwd_message.value = '请填写登陆密码!'
                p = false
            }
            if (!p) {
                return
            }
            var params = {
                server_account: account.value,
                server_password: password.value
            }, serverInfo = {}
            // subret = await ServerLogin(params)
            // console.log(subret)
            utils.post('serverLogin', params).then(res => {
                if (res.id) {
                    serverInfo = Object.assign(serverInfo, res)
                    let commonAccount = {
                        app_key: serverInfo.app_key,
                        account_id: serverInfo.account_id,
                        server_id: serverInfo.id
                    }
                    localStorage.setItem('commonAccount', JSON.stringify(commonAccount))
                    localStorage.setItem('serverInfo', JSON.stringify(serverInfo))
                    router.push('/workplatform')
                } else {
                    alert('登陆失败')
                }
            })
        }
        let bindAccountInput = () => {
            account_message.value = ''
        }
        let bindPassInput = () => {
            pwd_message.value = ''
        }
        return {
            account,
            password,
            account_message,
            pwd_message,
            accountInfo,
            submitLogin,
            bindAccountInput,
            bindPassInput
        }
    }
}
</script>
<style scoped lang="scss">
#login{
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    background-image: url('https://images.nbyuyuan.com/yyservice/nbyuyuan/login_bg.jpg');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    .login-main{
        .err_msg{
            font-size: 12px;
            color: red;
        }
        width: 90%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left{}
        .right{
            width: 400px;
            min-height: 300px;
            padding: 20px;
            border-radius: 20px;
            box-sizing: border-box;
            background-color: #fff;
            box-shadow: 0 0 10px 2px rgba(0,0,0,0.3);
            .login-title{
                font-size: 18px;
                text-align: center;
                margin-bottom: 20px;
                span{
                    padding: 5px 0;
                    border-bottom: 2px solid #000;
                }
            }
            .login-line{
                padding: 20px 0;
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: flex-end;
                i{
                    font-size: 24px;
                    color: #a2a2a2;
                }
                input {
                    width: calc(100% - 35px);
                    margin-left: 10px;
                    padding-left: 2px;
                    height: 30px;
                    font-size: 14px;
                    color: #333;
                    letter-spacing: 1px;
                    border-bottom: 1px solid #c2c2c2;
                }
            }
            .login-tools{
                justify-content: flex-end;
                span{
                    background-color: #333;
                    padding: 10px 20px;
                    border-radius: 10px;
                    font-size: 14px;
                    color: #fff;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>