import qs from 'qs';
import axios from 'axios'

const baseUrl = process.env.NODE_ENV === 'development' ? 'https://api.yyservice.nbyuyuan.net/api/' : 'https://api.yyservice.nbyuyuan.net/api/';
const headers = {
    get: {
        "request-header": "yuyuan-api"
        // 在开发中，一般还需要单点登录或者其他功能的通用请求头，可以一并配置进来
    },
    post: {
        'Content-Type': 'application/json;charset=utf-8'
        // 在开发中，一般还需要单点登录或者其他功能的通用请求头，可以一并配置进来
    }
};

function getQueryVariable(variable)
{
    var query = window.location.search.substring(1);
    console.log(query)
    var vars = query.split("&");
    for (var i=0;i<vars.length;i++) {
            var pair = vars[i].split("=");
            if(pair[0] == variable){return pair[1];}
    }
    return(false);
}

// 基本配置
const _axios = axios.create({
    headers,
    timeout: 12000,
    baseURL: baseUrl
})

// 请求拦截器
axios.interceptors.request.use((config) => {
    var appkey = getQueryVariable('appkey')
    console.log(config, qs, appkey)
    return config
})
// 响应拦截器
axios.interceptors.response.use((response) => {
    return Promise.resolve(response)
})
export default _axios