import axios from './axios'

function get(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params
        }).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

function post(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios.post(url, {
            params
        }).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export {
    get,
    post
}